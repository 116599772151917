<template>
  <v-row>
    <v-dialog
      v-model="open"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      transition="scale-transition"
      persistent
      scrollable
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ $t("subjects.master_subjects") }}
          <div style="position: absolute; right: 25px;">
            <v-icon @click="toggleDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="masterSubject"
            v-model="formMasterSubject"
            class="mt-4"
            lazy-validation
            @submit.prevent="add"
          >
            <v-row>
              <v-col cols="12" sm="6" id="top">
                <v-switch
                  v-model="itemMaster.withSub"
                  :label="$t('subjects.sub_subjects')"
                  class="ma-0"
                  hide-details
                  @change="withSub"
                />
              </v-col>
              <v-spacer />
              <v-col
                :class="$vuetify.breakpoint.mdAndDown && 'mt-2'"
                cols="12"
                sm="6"
                class="d-md-flex flex-md-row-reverse"
              >
                <v-checkbox
                  v-model="someStudent"
                  :label="$t('subjects.some_student')"
                  hide-details
                  class="mt-1"
                />
              </v-col>
              <v-col cols="12" class="mt-5">
                <v-text-field
                  v-model="itemMaster.lesson"
                  :label="$t('subjects.subjects')"
                  :rules="[v => !!v || $t('app.required')]"
                  :hide-details="formMasterSubject"
                  dense
                  outlined
                  required
                  @input="data => (itemMaster.lesson = data.toUpperCase())"
                />
              </v-col>
              <v-row class="mt-5 ml-1">
                <v-col
                  v-for="(sub, i) in itemMaster.sub_lesson.filter(
                    d => d != null
                  )"
                  :key="i"
                  class="mb-2 d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="itemMaster.sub_lesson[i]"
                    :label="$t('subjects.sub_subjects')"
                    :rules="[v => !!v || $t('app.required')]"
                    :hide-details="formMasterSubject"
                    dense
                    outlined
                    required
                    @input="
                      data => (itemMaster.sub_lesson[i] = data.toUpperCase())
                    "
                  />
                  <div class="ml-2">
                    <v-btn
                      v-if="lengthSubLesson == 1 || lengthSubLesson - 1 == i"
                      icon
                      dark
                      :class="lengthSubLesson - 1 == i && 'mr-1'"
                      class="primary"
                      :disabled="!itemMaster.withSub"
                      @click="addedSub"
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="
                        lengthSubLesson > 1 ||
                          (lengthSubLesson - 1 == i && lengthSubLesson > 1)
                      "
                      icon
                      dark
                      class="error"
                      :disabled="!itemMaster.withSub"
                      @click="deleteSub(i)"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-col cols="12">
                <div class="d-flex my-4">
                  <v-spacer />
                  <div v-if="editable">
                    <v-btn
                      dark
                      outlined
                      color="primary"
                      class="mr-3"
                      @click="resetForm"
                      >{{ $t("app.cancel") }}</v-btn
                    >
                    <v-btn
                      :loading="loadingBtn"
                      dark
                      depressed
                      class="gradient-primary"
                      @click="submitEditHandler"
                      >{{ $t("app.edit") }}</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-btn
                      class="mr-2"
                      outlined
                      color="primary"
                      @click="resetForm"
                    >
                      Reset
                    </v-btn>
                    <v-btn
                      outlined
                      class="gradient-primary"
                      dark
                      :loading="loadingBtn"
                      @click="add"
                      >{{ $t("app.save") }}</v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-data-table
            :headers="headerMasterSubject"
            :items="dataTable"
            :loading="loading"
            :expanded.sync="expanded"
            disable-pagination
            item-key="id_master"
            show-expand
            hide-default-footer
            class="elevation-0"
          >
            <template #item.data-table-expand="{ item, expand, isExpanded }">
              <v-btn v-if="item.id_sub" icon @click="expand(!isExpanded)"
                ><v-icon>mdi-chevron-down</v-icon></v-btn
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="item.sub_lesson.length > 0" :colspan="headers.length">
                <v-simple-table class="my-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("subjects.sub_subjects") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="d in item.sub_lesson" :key="d">
                        <td>
                          <div>
                            {{ d }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
              <td v-else :colspan="headers.length">
                <v-row class="my-4" no-gutters justify="center">
                  <span>{{ $t("app.no_data") }}</span>
                </v-row>
              </td>
            </template>
            <template v-slot:item.student="{ item }">
              <div v-if="item.type == 1">
                {{ $t("subjects.some_student") }}
              </div>
              <div v-else>{{ $t("subjects.all_student") }}</div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon dark class="primary mr-2" @click="editMaster(item)">
                <v-icon>mdi-pencil-box-outline</v-icon>
              </v-btn>

              <v-btn icon dark class="error" @click="isDialogDelete(item)">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ModalConfirm
      :close="() => (this.confirmDelete = false)"
      :loading="loadingDelete"
      :isOpen="confirmDelete"
      :save="deleteMasterSubjects"
      :content="`<span>${msgDelete()}</span>`"
    />
    <DialogForceDelete
      :subject="subjectName"
      :modal="confirmForceDelete"
      :bodyDelete="bodyDelete"
      :totalRelations="totalRelations"
      isMaster
      @close="
        () => {
          confirmForceDelete = false;
          getMasterLesson();
        }
      "
    />
  </v-row>
</template>

<script>
import {
  listMaster,
  addedMaster,
  deleteMaster,
  updateMaster
} from "@/api/admin/academic/subjects";
export default {
  props: {
    open: Boolean
  },
  components: {
    ModalConfirm: () => import("@/components/ModalConfirm"),
    DialogForceDelete: () => import("@/components/DialogForceDelete")
  },
  watch: {
    open(value) {
      if (value) {
        this.dataTable = [];
        this.expanded = [];
        this.getMasterLesson();
      }
    },
    "itemMaster.sub_lesson"(newValue) {
      if (this.editable) {
        this.lengthSubLesson = newValue.filter(d => d != null).length;
      } else {
        this.lengthSubLesson = newValue.length;
      }
    }
  },
  computed: {
    someStudent: {
      set(newValue) {
        newValue ? (this.itemMaster.type = 1) : (this.itemMaster.type = 0);
      },
      get() {
        return this.itemMaster.type == 0 ? false : true;
      }
    }
  },
  data() {
    return {
      expanded: [],
      loadingDelete: false,
      confirmDelete: false,
      itemMaster: {
        id_subjects: [],
        id_subSubjects: [],
        lesson: "",
        sub_lesson: [],
        withSub: false,
        type: 0
      },
      headerMasterSubject: [
        {
          text: this.$i18n.t("subjects.subjects"),
          value: "lesson"
        },
        {
          text: this.$i18n.t("app.student"),
          value: "student"
        },
        {
          text: this.$i18n.t("subjects.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      editable: false,
      masterSubject: [],
      formMasterSubject: false,
      form: {
        name: "",
        student: false
      },
      loadingBtn: false,
      dataTable: [],
      loading: false,
      idMasterSubject: 0,
      originalDataMaster: [],
      idMastersDelete: [],
      idMasterEdit: 0,
      lengthSubLesson: 0,
      confirmForceDelete: false,
      subjectName: "",
      nameClass: "",
      totalRelations: 0,
      bodyDelete: { id: 0 }
    };
  },
  methods: {
    msgDelete() {
      return (
        this.$i18n.t("app.confirm_delete") +
        "<br />" +
        this.$i18n.t("subjects.msg_response.master_subjects.info_deleted")
      );
    },
    isDialogDelete(item) {
      this.idMasterSubject = item.id_master;
      this.bodyDelete.id = item.id_master;
      this.subjectName = item.lesson;
      this.confirmDelete = true;
    },
    toggleDialog() {
      this.resetForm();
      this.$emit("toggle");
    },
    add() {
      if (this.$refs.masterSubject.validate()) {
        this.loadingBtn = true;
        addedMaster({ data: this.itemMaster })
          .then(() => {
            this.loadingBtn = false;
            this.resetForm();
            this.snackBar(
              true,
              this.$i18n.t(
                "subjects.msg_response.master_subjects.success_added"
              )
            );
            this.getMasterLesson();
          })
          .catch(err => {
            console.error("Error at method add()\n", err);
            this.loadingBtn = false;
          });
      }
    },
    addedSub() {
      let index = this.itemMaster.sub_lesson.findIndex(d => d === null);
      if (index === -1) {
        this.itemMaster.sub_lesson.push("");
        this.itemMaster.id_subjects.push(this.idMasterEdit);
        this.itemMaster.id_subSubjects.push(null);
      } else {
        this.itemMaster.sub_lesson.splice(index, 0, "");
        this.itemMaster.id_subjects.splice(index, 0, this.idMasterEdit);
        this.itemMaster.id_subSubjects.splice(index, 0, null);
      }
    },
    deleteSub(index) {
      let tempIdSubjects = this.editable
        ? this.itemMaster.id_subjects[index]
        : null;
      let tempIdSubSubjects = this.editable
        ? this.itemMaster.id_subSubjects[index]
        : null;
      this.$refs.masterSubject.resetValidation();
      this.itemMaster.sub_lesson.splice(index, 1);
      this.itemMaster.id_subjects.splice(index, 1);
      this.itemMaster.id_subSubjects.splice(index, 1);
      if (this.editable) {
        let index = this.itemMaster.sub_lesson.findIndex(d => d === null);
        if (index === -1) {
          this.itemMaster.sub_lesson.push(null);
          this.itemMaster.id_subjects.push(tempIdSubjects);
          this.itemMaster.id_subSubjects.push(tempIdSubSubjects);
        } else {
          this.itemMaster.sub_lesson.splice(index, 0, null);
          this.itemMaster.id_subjects.splice(index, 0, tempIdSubjects);
          this.itemMaster.id_subSubjects.splice(index, 0, tempIdSubSubjects);
        }
      }
    },
    withSub(newValue) {
      if (newValue) {
        this.itemMaster.sub_lesson.push("");
        this.itemMaster.id_subjects.push(null);
        this.itemMaster.id_subSubjects.push(null);
      } else {
        this.itemMaster.sub_lesson = [];
        this.itemMaster.id_subjects = [];
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    getMasterLesson() {
      this.loading = true;
      listMaster()
        .then(res => {
          const r = res.data.data;
          this.originalDataMaster = r;
          const temp = r.filter((value, index, array) =>
            value.sub
              ? array.findIndex(item => item.id_master === value.id_master) ===
                index
              : value
          );
          temp.map(d => {
            d.sub_lesson = [];
            d.id_subSubjects = [];
            d.id_subjects = [];
            if (d.sub) {
              d.withSub = true;
              r.map(item => {
                if (item.id_master == d.id_master) {
                  d.sub_lesson.push(item.sub);
                  d.id_subjects.push(item.id_master);
                  d.id_subSubjects.push(item.id_sub);
                }
              });
            }
          });
          this.dataTable = temp;
          this.loading = false;
        })
        .catch(err => {
          console.error("Error at method getMasterLesson()\n", err);
          this.loading = false;
        });
    },
    editMaster(item) {
      document
        .querySelector("#top")
        .scrollIntoView({ behavior: "smooth", block: "center" });
      this.idMasterEdit = item.id_master;
      this.getMasterLesson();
      this.itemMaster = { ...item };
      this.editable = true;
    },
    deleteMasterSubjects() {
      this.loadingDelete = true;
      deleteMaster({
        id: this.idMasterSubject
      })
        .then(res => {
          const data = res.data;
          if (data.code) {
            switch (data.code) {
              case 1:
                this.loadingDelete = false;
                this.snackBar(
                  true,
                  this.$i18n.t(
                    "subjects.msg_response.master_subjects.success_deleted"
                  )
                );
                this.resetForm();
                break;
              case 2:
                this.confirmForceDelete = true;
                this.totalRelations = data.data.data_relation;
                break;
            }
          } else {
            this.snackBar(false, data.message);
          }
          this.loadingDelete = false;
          this.confirmDelete = false;
        })
        .catch(error => {
          this.loadingDelete = false;
          console.error("deleteMasterSubjects()\n", error);
          this.confirmDelete = false;
        });
    },
    submitEditHandler() {
      if (this.$refs.masterSubject.validate()) {
        this.loadingBtn = true;
        let bodyUpdate = { data: [] };
        if (this.itemMaster.sub_lesson.length) {
          this.itemMaster.sub_lesson.forEach((d, i) => {
            bodyUpdate.data.push({
              id_master: this.idMasterEdit,
              id_sub: this.itemMaster.id_subSubjects[i],
              sub_lesson: this.itemMaster.sub_lesson[i],
              lesson: this.itemMaster.lesson,
              type: this.itemMaster.type
            });
          });
        } else {
          bodyUpdate.data.push({
            id_master: this.idMasterEdit,
            id_sub: null,
            sub_lesson: null,
            lesson: this.itemMaster.lesson,
            type: this.itemMaster.type
          });
        }
        updateMaster(bodyUpdate)
          .then(res => {
            if (res.data.code == 1) {
              this.editable = false;
              this.resetForm();
              this.snackBar(
                true,
                this.$i18n.t(
                  "subjects.msg_response.master_subjects.success_edited"
                )
              );
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(err => {
            this.loadingBtn = false;
            console.error("Error at method submitEditHanlder()\n", err);
          });
      }
    },
    resetForm() {
      this.getMasterLesson();
      this.editable = false;
      this.itemMaster = {
        id_subjects: [],
        id_subSubjects: [],
        lesson: "",
        sub_lesson: [],
        withSub: false,
        type: 0
      };
      this.$refs.masterSubject.resetValidation();
    }
  }
};
</script>
